/** Generated js for domain-specific scripting
 * by common\modules\domains\helpers\DomainJavascriptWriter
 */

 import Swiper from 'swiper';
 import AOS from 'aos';

 jQuery(function ($) {

    const burgers = document.querySelectorAll('.burger-menu');
    const header = document.querySelector('.navigation-main');
    const burgerText = document.querySelector('.burger-text');
    const items = document.querySelectorAll('.nav-link');
        
    items.forEach(function(link) {
        $(link).click(function() {
            $(burger).parent().removeClass('navigation-main-open');
            $(burgerText).parent().removeClass('menu-open');
            $(header).removeClass('navigation-open');   
        });
    });

    // document.body.addEventListener('click', (e) => {
    //     console.log(e)
    // })
    
    burgers.forEach(function(burger) {
        // burger.addEventListener('click', () => console.log('click'));
        
        burger.onclick = function () {
           
            $(this).parent().toggleClass('navigation-main-open');
            $(burgerText).parent().toggleClass('menu-open');
            $(header).toggleClass('navigation-open');
        };
    });
});



$(window).scroll(function() {
    var scroll = window.scrollY;
    if ( scroll >= 900) {
        $('.navigation-main').addClass('fixed');
    } else if(scroll < 900) {
        $('.navigation-main').removeClass('fixed');
    }
});


$(document).ready(function() {

    var active = $('.nav-link.active').position()?.top;
    active = active + 10;
    var triangle = $('.triangle');

    triangle.css({"top": `${active}px`});

    var all = document.querySelectorAll('.nav-link');

    all.forEach(function(link) {
        $(link).mouseover(function() {
            var top = $(link).position().top;
            top = top + 10;
            triangle.css({"top": `${top}px`});
        });
        $(link).mouseleave(function() {
            var active = $('.nav-link.active').position().top;
            active = active + 10;


            triangle.css({"top": `${active}px`});
        });
    });
});




// bootstrap 5 multi-carousel 3 items per page
const multiCarousels = document.querySelectorAll('.multi-carousel')
multiCarousels.forEach((multiCarousel) => {
    console.log(multiCarousel)
    let items = multiCarousel.querySelectorAll('.carousel-item')
    
    items.forEach((el) => {
        const minPerSlide = 3
        let next = el.nextElementSibling
        for (var i=1; i<minPerSlide; i++) {
            if (!next) {
                // wrap carousel by using first child
            	next = items[0]
          	}
            let cloneChild = next.cloneNode(true)
            el.appendChild(cloneChild.children[0])
            next = next.nextElementSibling
        }
    })

})


    





const uspSlider = new Swiper('.usp-slider', {
   // Optional parameters
   speed: 900,
   spaceBetween:              20,
   containerModifierClass:    'usp-slider-', // instead of 'swiper-container-'
   wrapperClass:              'usp-wrapper', // instead of 'swiper-wrapper'
   slideClass:                'usp-item', // instead of 'swiper-slide'
   slideActiveClass:          'usp-item-active', // instead of 'swiper-slide-active'
   slideBlankClass:           'usp-item-invisible-blank', // instead of 'swiper-slide-invisible-blank'
   slideDuplicateClass:       'usp-item-duplicate', // instead of 'swiper-slide-duplicate'
   slideDuplicateActiveClass: 'usp-item-duplicate-active', // instead of 'swiper-slide-duplicate-active'
   slideDuplicateNextClass:   'usp-item-duplicate-next', // instead of 'swiper-slide-duplicate-next'
   slideDuplicatePrevClass:   'usp-item-duplicate-prev', // instead of 'swiper-slide-duplicate-prev'
   slideNextClass:            'usp-item-next', // instead of 'swiper-slide-next'
   slidePrevClass:            'usp-item-prev', // instead of 'swiper-slide-prev'
   clickableClass:            'usp-slider-pagination-clickable', // instead of 'swiper-pagination-clickable',

   centeredSlides: false,
   direction: 'horizontal',
   loop: true,
   autoplay: {
      delay: 2000,
   },

   navigation: {
     nextEl: '.usp-button-next',
     prevEl: '.usp-button-prev',
     clickable: true,
   },

   breakpoints: {
      476: { //sm
         slidesPerView: 2,
         slidesPerGroup: 1
      },
      768: { // md
         slidesPerView: 3,
         slidesPerGroup: 1
      },
      1200: { // xl
          slidesPerView: 4,
          slidesPerGroup: 1
      },
  }
});

const planningSlider = new Swiper('.planning-slider', {
   // Optional parameters
   speed: 900,
   spaceBetween:              0,
   containerModifierClass:    'planning-slider-', // instead of 'swiper-container-'
   wrapperClass:              'planning-wrapper', // instead of 'swiper-wrapper'
   slideClass:                'planning-item', // instead of 'swiper-slide'
   slideActiveClass:          'planning-item-active', // instead of 'swiper-slide-active'
   slideBlankClass:           'planning-item-invisible-blank', // instead of 'swiper-slide-invisible-blank'
   slideDuplicateClass:       'planning-item-duplicate', // instead of 'swiper-slide-duplicate'
   slideDuplicateActiveClass: 'planning-item-duplicate-active', // instead of 'swiper-slide-duplicate-active'
   slideDuplicateNextClass:   'planning-item-duplicate-next', // instead of 'swiper-slide-duplicate-next'
   slideDuplicatePrevClass:   'planning-item-duplicate-prev', // instead of 'swiper-slide-duplicate-prev'
   slideNextClass:            'planning-item-next', // instead of 'swiper-slide-next'
   slidePrevClass:            'planning-item-prev', // instead of 'swiper-slide-prev'
   clickableClass:            'planning-slider-pagination-clickable', // instead of 'swiper-pagination-clickable',

   centeredSlides: true,
   direction: 'horizontal',
   loop: true,
   autoplay: {
      delay: 3500,
   },

   navigation: {
     nextEl: '.planning-button-next',
     prevEl: '.planning-button-prev',
     clickable: true,
   },

   breakpoints: {
      // 476: { //sm
      //    slidesPerView: 2,
      //    slidesPerGroup: 2
      // },
      768: { // md
         slidesPerView: 1,
         slidesPerGroup: 1
      },
      1200: { // xl
          slidesPerView: 1,
          slidesPerGroup: 1
      },
  }
});

const hotSpots = new Swiper('.hotspots-slider', {
    speed: 900,
    spaceBetween: 20,
    containerModifierClass: 'hotspots-slider-',
    wrapperClass: 'hotspots-wrapper',
    slideClass: 'hotspots-item',
    slideActiveClass: 'hotspots-item-active',
    slideBlankClass: 'hotspots-item-invisible-blank',
    slideDuplicateClass: 'hotspots-item-duplicate',
    slideDuplicateActiveClass: 'hotspots-item-duplicate-active',
    slideDuplicateNextClass: 'hotspots-item-duplicate-next',
    slideDuplicatePrevClass: 'hotspots-item-duplicate-prev',
    slideNextClass: 'hotspots-item-next',
    slidePrevClass: 'hotspots-item-prev',
    clickableClass: 'hotspots-slider-pagination-clickable',

    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 2000,
    },

    navigation: {
        nextEl: '.hotspots-button-next',
        prevEl: '.hotspots-button-prev',
        clickable: true,
    },

    breakpoints: {
        // 476: { //sm
        //    slidesPerView: 2,
        //    slidesPerGroup: 2
        // },
        768: {
            // md
            slidesPerView: 1.5,
            slidesPerGroup: 1
        },
        1200: {
            // xl
            slidesPerView: 2.5,
            slidesPerGroup: 1
        },
    }
});




const leveninwoerden = new Swiper('.leveninwoerden-slider', {
    speed: 900,
    spaceBetween: 20,
    containerModifierClass: 'leveninwoerden-slider-',
    wrapperClass: 'leveninwoerden-wrapper',
    slideClass: 'leveninwoerden-item',
    slideActiveClass: 'leveninwoerden-item-active',
    slideBlankClass: 'leveninwoerden-item-invisible-blank',
    slideDuplicateClass: 'leveninwoerden-item-duplicate',
    slideDuplicateActiveClass: 'leveninwoerden-item-duplicate-active',
    slideDuplicateNextClass: 'leveninwoerden-item-duplicate-next',
    slideDuplicatePrevClass: 'leveninwoerden-item-duplicate-prev',
    slideNextClass: 'leveninwoerden-item-next',
    slidePrevClass: 'leveninwoerden-item-prev',
    clickableClass: 'leveninwoerden-slider-pagination-clickable',

    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 2000,
    },

    navigation: {
        nextEl: '.leveninwoerden-button-next',
        prevEl: '.leveninwoerden-button-prev',
        clickable: true,
    },

    breakpoints: {
        // 476: { //sm
        //    slidesPerView: 2,
        //    slidesPerGroup: 2
        // },
        768: {
            // md
            slidesPerView: 3,
            slidesPerGroup: 1
        },
        1200: {
            // xl
            slidesPerView: 4,
            slidesPerGroup: 1
        },
    }
});



const opgeleverd = new Swiper('.opgeleverd-slider', {
    speed: 900,
    spaceBetween: 20,
    containerModifierClass: 'opgeleverd-slider-',
    wrapperClass: 'opgeleverd-wrapper',
    slideClass: 'opgeleverd-item',
    slideActiveClass: 'opgeleverd-item-active',
    slideBlankClass: 'opgeleverd-item-invisible-blank',
    slideDuplicateClass: 'opgeleverd-item-duplicate',
    slideDuplicateActiveClass: 'opgeleverd-item-duplicate-active',
    slideDuplicateNextClass: 'opgeleverd-item-duplicate-next',
    slideDuplicatePrevClass: 'opgeleverd-item-duplicate-prev',
    slideNextClass: 'opgeleverd-item-next',
    slidePrevClass: 'opgeleverd-item-prev',
    clickableClass: 'opgeleverd-slider-pagination-clickable',

    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 2000,
    },

    navigation: {
        nextEl: '.opgeleverd-button-next',
        prevEl: '.opgeleverd-button-prev',
        clickable: true,
    },

    breakpoints: {
        // 476: { //sm
        //    slidesPerView: 2,
        //    slidesPerGroup: 2
        // },
        768: {
            // md
            slidesPerView: 3,
            slidesPerGroup: 1
        },
        1200: {
            // xl
            slidesPerView: 4,
            slidesPerGroup: 1
        },
    }
});




const faciliteiten = new Swiper('.faciliteiten-slider', {
speed: 900,
spaceBetween: 20,
containerModifierClass: 'faciliteiten-slider-',
wrapperClass: 'faciliteiten-wrapper',
slideClass: 'faciliteiten-item',
slideActiveClass: 'faciliteiten-item-active',
slideBlankClass: 'faciliteiten-item-invisible-blank',
slideDuplicateClass: 'faciliteiten-item-duplicate',
slideDuplicateActiveClass: 'faciliteiten-item-duplicate-active',
slideDuplicateNextClass: 'faciliteiten-item-duplicate-next',
slideDuplicatePrevClass: 'faciliteiten-item-duplicate-prev',
slideNextClass: 'faciliteiten-item-next',
slidePrevClass: 'faciliteiten-item-prev',
clickableClass: 'faciliteiten-slider-pagination-clickable',

direction: 'horizontal',
loop: true,
autoplay: {
    delay: 2000,
},

navigation: {
    nextEl: '.faciliteiten-button-next',
    prevEl: '.faciliteiten-button-prev',
    clickable: true,
},

breakpoints: {
// 476: { //sm
      //    slidesPerView: 2,
      //    slidesPerGroup: 2
      // },
768: {
   // md
   slidesPerView: 1,
   slidesPerGroup: 1
   },
1200: {
    // xl
    slidesPerView: 1,
    slidesPerGroup: 1
    },
}
});


  // ***************************** //
 //         SmoothScroll         //
// ***************************** //
// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function (event) {
    // On-page links
    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
    ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - 10
            }, 1000, function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                }
            });
        }
    }
});


// ***************************** //
//        Animate On Scroll      //
// ***************************** //

AOS.init({
 disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
 debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
 throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

 offset: 50, // offset (in px) from the original trigger point
 delay: 0, // values from 0 to 3000, with step 50ms
 duration: 800, // values from 0 to 3000, with step 50ms
 easing: 'ease', // default easing for AOS animations
 once: true, // whether animation should happen only once - while scrolling down
 mirror: false, // whether elements should animate out while scrolling past them
 anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});